import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import "./style.scss"

const ImageCarousel = props => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <div className="use-bootstrap">
      <Carousel activeIndex={index} onSelect={handleSelect} interval={50000}>
        {props.imageArray.map((image, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={image.image}
                alt={image.alt}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
    </div>
  )
}

export default ImageCarousel
