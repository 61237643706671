import React, { useState } from "react"
import Spinner from "react-bootstrap/Spinner"

import "./style.scss"

const VideoPlayer = props => {
  const [loading, setLoading] = useState(true)

  return (
    <>
      {loading && (
        <div className="spinner-div">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      <iframe
        className={`iframe ${
          loading ? "iframe-opacity" : "iframe-opacity-transition"
        }`}
        title={props.title}
        src={props.src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </>
  )
}
export default VideoPlayer
