import React from "react"
import { Link, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import PageLayout from "../../components/pageLayout/index"
import ImageCarousel from "../../components/ImageCarousel/index"
import VideoPlayer from "../../components/VideoPlayer/index"
import SEOHelmet from "../../components/SEOHelmet/index"

const portfolioProject = ({ data }) => {
  const project = data.sanityEntryProject || {}

  // Get slugs for previous and next project page
  const portfolioProjects = data.sanitySectionPortfolio?.projects
  const projectIndex = portfolioProjects.findIndex(
    p => p.slug.current === project?.slug?.current
  )
  const previousProject = portfolioProjects[projectIndex - 1]?.slug?.current
  const nextProject = portfolioProjects[projectIndex + 1]?.slug?.current

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
    marks: {
      internalLink: ({ mark, children }) => {
        const projectIndex = portfolioProjects?.findIndex(
          p => p._id === mark.reference._ref
        )
        const href = `/portfolio/${portfolioProjects[projectIndex]?.slug?.current}`
        return <Link to={href}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        )
      },
    },
  }

  const singleImage = project.images?.length === 1

  const imageCarousel = project.images?.length > 1
  const imageArray = project.images?.map(image => ({
    image: image.image.asset.url,
    alt: image.alt,
  }))

  const videoDiv = project.videos?.length > 0

  return (
    <PageLayout
      previous={previousProject ? `/portfolio/${previousProject}` : null}
      next={nextProject ? `/portfolio/${nextProject}` : null}
      hasArrows={true}
    >
      <SEOHelmet title={`Portfolio: ${project.name}`} />
      <div className="page-div-padding page-padding">
        <h1 className="page-div-header">{project.name}</h1>
        <div className="project-div">
          <div className="project-text">
            <BlockContent
              blocks={project._rawProjectText}
              serializers={serializers}
            />

            {project.quotes?.map((q, i) => (
              <div className="quote-div" key={i}>
                <blockquote>
                  <p>
                    “{q.quote}
                    {q.stars && " "}
                    {q.stars &&
                      Array.from({ length: q.stars }, (_, i) => (
                        <span className="star-span" key={i}>
                          &#9733;
                        </span>
                      ))}
                    ”
                  </p>
                  <cite>- {q.source}</cite>
                </blockquote>
              </div>
            ))}

            <div className="project-logo-div-bottom">
              {project.logos?.map((image, i) => (
                <img key={i} src={image.image.asset.url} alt={image.alt} />
              ))}
            </div>
          </div>

          <div className="media-div">
            {singleImage && (
              <div className="image-div">
                <img
                  src={project.images[0].image.asset.url}
                  alt={project.images[0].alt}
                />
              </div>
            )}
            {imageCarousel && (
              <div className="carousel-div">
                <ImageCarousel imageArray={imageArray} />
              </div>
            )}
            {videoDiv &&
              project.videos.map((video, i) => (
                <div className="iframe-div" key={i}>
                  <VideoPlayer src={video.url} title={video.title} />
                </div>
              ))}
          </div>
        </div>

        <div className="project-logo-div__small-width">
          {project.logos?.map((logo, i) => (
            <img key={i} src={logo.image.asset.url} alt={logo.alt} />
          ))}
        </div>
        {project.credit && (
          <p className="project-tile-credit">{project.credit}</p>
        )}
      </div>
    </PageLayout>
  )
}

export default portfolioProject

export const query = graphql`
  query PortfolioProjectQuery($projectId: String) {
    sanityEntryProject(id: { eq: $projectId }) {
      name
      slug {
        current
      }
      _rawProjectText
      videos {
        url
        title
      }
      images {
        image {
          asset {
            url
          }
        }
        alt
      }
      quotes {
        quote
        source
        stars
      }
      logos {
        image {
          asset {
            url
          }
        }
        alt
      }
      credit
    }

    sanitySectionPortfolio(projects: {}) {
      projects {
        _id
        slug {
          current
        }
      }
    }
  }
`
